import { useEffect, useRef } from 'react'
import './App.css'

import logo from './assets/211015_docomobusiness_rgb_color.png'
import smartvliveLogo from './assets/smart-vlive-logo-without-tm.png'

const liveId = process.env.REACT_APP_LIVEID || 'hhql51ribcjaszzm'
const wsEndpoint = process.env.REACT_APP_WS_ENDPOINT || 'wss://ils-sink-eks-prod-1.m-pipe.net'
const guestId = process.env.REACT_APP_GUEST_ID || '11e0975d00d5bd343236ad63e34775fa6ad56900d19f0fb60d09533172d21001'

const allowFullScreen = true
const quality = process.env.REACT_APP_QUALITY || 2

const playerUrl = process.env.REACT_APP_PLAYER_URL || 'https://player.smartvlive.jp'
const studioUrl = process.env.REACT_APP_STUDIO_URL || 'https://studio.m-pipe.net'

const queryParams = Object.entries({ liveId, wsEndpoint, guestId, allowFullScreen, quality })
  .reduce(( prev, [ key, val ]) => {
    return [ ...prev, `${key}=${encodeURIComponent(val)}` ]
  }, []).join('&')
//const defaultUrl = {
//  vLive: 'https://player.smartvlive.jp/?liveId=pbfs4gcejgel20gc&wsEndpoint=wss%3A%2F%2Fsink-dev.smartvlive.jp&allowFullScreen=true',
//  guestView: 'https://rtcgw-mixer.m-pipe.net/guest-room/0531895a67c46697c59b65986f8bc7171c140e0448e002494f549ac0f280d2ea9d33eaddaa3e452e8105db56fa2fbf02',
//}

const url = {
  vLive: `${playerUrl}/?${queryParams}`,
  guestView: `${studioUrl}/guest-room/${guestId}`,
}
console.log( url )

function App() {
  const _vlive = useRef()
  useEffect( () => {
    window.onmessage = ev => {
      const { type, value } = ev.data

      if( type === 'status' ) {
        let message
        const id = Date.now().toString()
        if( value === 'IDLE' ) {
          message = {
            type: "ils-request",
            meta: { id },
            payload: {key: "audioMute", value: false }}
        } else if ( value === 'PRODUCING' ) {
          message = {
            type: "ils-request",
            meta: { id },
            payload: {key: "audioMute", value: true }}
        }
        if( message ) {
          _vlive.current.contentWindow.postMessage( message, '*' )
        }
      }
    }
  }, [])
  return (
    <div className="App">
      <header>
        <img src={logo} height={50} alt="logo of docomo business" />
      </header>
      <main>
      <div className='smart-vlive'>
        <iframe
          allow="fullscreen"
          title="smart vLive"
          src={url.vLive}
          scrolling="no"
          frameBorder="0"
          ref={ _vlive }
        />
      </div>
      <div className='main-board'>
        <img src={smartvliveLogo} width="75%" alt="logo of Smart vLive" />
        <div className='lead'>
          demo of ultra low latency<br/>interactive live
        </div>
      </div>

      <div className='kokutele-studio'>
        <iframe
          title="kokutele-studio"
          src={url.guestView}
          allow="camera *;microphone *"
          scrolling="no"
          frameBorder="0"
        ></iframe>
      </div>
      </main>
      <footer>
        &copy; NTT Communications Corporation all rights reserved.
      </footer>
    </div>
  );
}

export default App;
